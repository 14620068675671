const searchFiltersQuery = () => {
    const query = gql`
        query searchFilters($center: String, $locale: LocaleInputType) {
            searchFilters(center: $center, locale: $locale) {
                topics {
                    docs {
                        id
                        title
                    }
                }
                regions {
                    docs {
                        id
                        title
                        type
                        regions {
                            id
                            title
                        }
                    }
                }
                experts {
                    docs {
                        id
                        title
                        lastName
                    }
                }
                years {
                    docs
                }
                types {
                    docs
                }
            }
        }
    `;

    return query;
};

export default searchFiltersQuery;

import searchFiltersQuery from '@gql/queries/searchFiltersQuery';

export default async function () {
    const route = useRoute();
    const { currentCenter } = useCenters();
    const { locale } = useLocale();

    const query = searchFiltersQuery();

    const variables = {
        center: currentCenter.value.slug,
        locale: locale._value || 'en',
    };

    const result = await useAsyncQuery(query, variables);

    return result;
}

<template>
    <FormField class="checkbox">
        <span class="content">
            <input
                :checked="isChecked"
                @change="$emit('update:isChecked', $event.target.checked)"
                v-uid
                ref="input"
                type="checkbox"
                class="checkbox"
                :aria-label="ariaLabel"
            />

            <label v-if="$slots.default" :for="input?.id" class="label">
                <slot />
            </label>
        </span>
    </FormField>
</template>

<script setup>
const props = defineProps({
    isChecked: {
        type: Boolean,
        default: false,
    },
    isPreChecked: {
        type: Boolean,
        default: false,
    },
    ariaLabel: {
        type: String,
        default: '',
    },
});

const input = ref();
</script>

<style lang="scss" scoped>
.content {
    display: inline-flex;
    align-items: center;
    gap: 0.44em;
}

input {
    appearance: auto;
    width: 1.5rem;
    height: 1.5rem;
}

.label {
    cursor: pointer;
}
</style>

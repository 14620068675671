<template>
    <div v-show="totalPages > 1" class="pagination">
        <div class="wrapper">
            <Button
                v-show="currentPageNumber > 1"
                @click="$emit('previous')"
                variant="tertiary"
                icon="arrow-backward-circled"
                iconPosition="left"
                >{{ $t('Prev') }}</Button
            >

            <div class="page-numbers">
                <div v-show="currentPageNumber > 2">
                    <button
                        @click="$emit('pageSelect', 1)"
                        :class="{ 'is-active': currentPageNumber === 1 }"
                        class="page-number"
                    >
                        <Typography variant="label">{{ 1 }}</Typography>
                    </button>

                    <Typography variant="label" class="ellipsis">...</Typography>
                </div>

                <ul class="local-page-numbers">
                    <li v-for="pageNumber in localPageNumbers" :key="pageNumber">
                        <button
                            :class="{ 'is-active': currentPageNumber === pageNumber }"
                            @click="$emit('pageSelect', pageNumber)"
                            class="page-number"
                        >
                            <Typography variant="label">{{ pageNumber }}</Typography>
                        </button>
                    </li>
                </ul>

                <div v-show="currentPageNumber < totalPages - 2">
                    <Typography variant="label" class="ellipsis">...</Typography>

                    <button
                        @click="$emit('pageSelect', totalPages)"
                        :class="{ 'is-active': currentPageNumber === totalPages }"
                        class="page-number"
                    >
                        <Typography variant="label">{{ totalPages }}</Typography>
                    </button>
                </div>
            </div>

            <Button
                v-show="currentPageNumber !== totalPages"
                @click="$emit('next')"
                variant="tertiary"
                icon="arrow-forward-circled"
                iconPosition="right"
                >{{ $t('Next') }}</Button
            >
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    nextPage: {
        type: Number,
    },
    prevPage: {
        type: Number,
    },
    totalDocs: {
        type: Number,
    },
    totalPages: {
        type: Number,
    },
});

const emit = defineEmits(['next', 'previous', 'pageSelect']);

const previousPageNumber = computed(() => {
    return props.prevPage || 0;
});

const nextPageNumber = computed(() => {
    return props.nextPage || props.totalPages;
});

const currentPageNumber = computed(() => {
    return previousPageNumber.value + 1;
});

const localPageNumbers = computed(() => {
    if (props.totalPages === 2) {
        return [1, 2];
    } else if (currentPageNumber.value < 3) {
        return [1, 2, 3];
    } else if (currentPageNumber.value >= props.totalPages - 2) {
        return [props.totalPages - 2, props.totalPages - 1, props.totalPages];
    } else {
        return [previousPageNumber.value, currentPageNumber.value, nextPageNumber.value];
    }
});
</script>

<style lang="scss" scoped>
.pagination {
    @include content-section;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.wrapper {
    display: inline-flex;
    align-items: center;
}

.page-numbers {
    display: inline-flex;
    align-items: center;
    padding: 0 2.4rem;
}

.page-number {
    padding: 1rem;
    color: color(offnoir);
    opacity: 0.4;
    flex: 0 1 auto;

    &.is-active {
        opacity: 1;
        background-color: color(noir, default, 0.03);
        border-radius: 0.4rem;
    }
}

.ellipsis {
    opacity: 0.4;
}

.local-page-numbers {
    display: inline-flex;
}
</style>
